<template>
  <div>
    <vs-card>
      <vs-button @click="exportFile()" class="mr-6">Exportar</vs-button>
    </vs-card>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import { notifyError } from "../utils/helpers";
export default {
  methods: {
    exportFile() {
      new siriusAPI()
        .exportProjects()
        .then((res) => {
          const href = URL.createObjectURL(res.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "projects.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(
            this,
            "Erro ao tentar carregar a lista de projetos: " + error
          );
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.alert {
  color: #f00;
}
.bt {
  margin-right: 1em;
}
.duplicated {
  color: red;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
